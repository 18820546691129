import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Select, Button, Tooltip, message } from 'antd';
import { ThunderboltOutlined } from '@ant-design/icons';
import { T, always, and, assoc, compose, cond, defaultTo, equals, isNil, not, or, path, pick, prop, trim } from 'ramda';
import { isNotNil, isNotNilOrEmpty, isTrue } from 'ramda-adjunct';
import { Section, Title, ChevronBottom } from '../../../../components';
import UpdateGlobalVariableMutation from '../../../../_graphql/mutations/documents/UpdateGlobalVariableMutation';
import CreateGlobalVariableMutation from '../../../../_graphql/mutations/documents/CreateGlobalVariableMutation';
import { callbackSave, errorMessage } from '../../../../utils/messageMutation';

const useGlobalVariable = ({ globalVariableId, globalVariable, scope, offerId, modalMode, setGlobalVariableCreated }) => {
  const globalVariableExist = isNotNil(globalVariableId);
  const [state, setState] = useState(defaultTo({}, globalVariable));

  const history = useHistory();

  const isEdit = !isNil(globalVariableId);

  const isValid = isNotNilOrEmpty(prop('name', state)) && isNotNilOrEmpty(prop('type', state));

  const onSave = () => {
    if (isEdit) {
      const globalVariable = compose(
        (gv) => ({ ...gv, name: trim(prop('name', gv)) }),
        pick(['name', 'type'])
      )(state);
      UpdateGlobalVariableMutation({ globalVariableId, globalVariable }, callbackSave('variables-globales', 'La variable globale a bien été mise à jour.', history, scope, state, offerId));
    } else {
      const globalVariable = compose(
        assoc('offerId', offerId),
        assoc('scope', scope),
        assoc('name', trim(prop('name', state)))
      )(state);
      CreateGlobalVariableMutation({ globalVariable }, (ok, error, globalVariable) => {
        if (ok && !error) {
          message.success('La variable globale a bien été créée.');
          if (!modalMode) {
            const destination = `/offre/${offerId || prop('offerId', state)}/edition`;
            history.push(destination);
          } else {
            setGlobalVariableCreated({
              ...state, id: prop('globalVariableId', globalVariable), name: prop('name', globalVariable), dates: { creation: new Date() }
            });
          }
        } else {
          if (equals('name_already_exist', error)) {
            message.error('Le nom indiqué existe déjà.');
          } else {
            errorMessage();
          }
        }
      });
    }
  };

  return {
    ...state,
    setState,
    isEdit,
    onSave,
    isValid,
    globalVariableExist
  };
};

const GlobalVariableForm = ({
  globalVariableId,
  globalVariable,
  scope,
  offerId,
  preview,
  modalMode = false,
  setGlobalVariableCreated
}) => {
  const {
    isEdit,
    name,
    type,
    setState,
    onSave,
    isValid,
    globalVariableExist
  } = useGlobalVariable({
    globalVariableId, globalVariable, scope, offerId, modalMode, setGlobalVariableCreated
  });
  const globalVariableEditable = and(or(isEdit, globalVariableExist), not(preview));
  const title = cond([
    [() => globalVariableEditable, always('Modifier la variable globale')],
    [() => isTrue(preview), always('Prévisualiser la variable globale')],
    [T, always('Créer une variable globale')]
  ])(globalVariable);

  const backToTitle = cond([
    [() => modalMode, always(null)],
    [isNotNil, always(`/offre/${offerId}/edition`)]
  ])(offerId);

  return (
    <>
      <Title
        title={title}
        backTo={backToTitle}
      />
      <Section title="nom de la variable globale">
        <div className="flex gap-4">
          <Input
            value={name}
            placeholder="Nom de la variable globale"
            onChange={(e) => setState(assoc('name', path(['target', 'value'], e)))}
            size="large"
            disabled={preview}
          />
          <Tooltip
            title={prop('isUsed', globalVariable) && 'Cette variable est utilisée dans un document, vous ne pouvez pas modifier son type.'}
            color="#0197DC"
          >
            <Select
              value={type}
              onChange={(v) => setState(assoc('type', v))}
              placeholder="Type de la variable globale"
              className="w-300px"
              size="large"
              suffixIcon={<ChevronBottom />}
              disabled={preview || prop('isUsed', globalVariable)}
            >
              <Select.Option value="text">Chaîne de caractère</Select.Option>
              <Select.Option value="number">Nombre</Select.Option>
              <Select.Option value="boolean">Booléen</Select.Option>
            </Select>
          </Tooltip>
        </div>
      </Section>
      {!preview && (
        <Button
          onClick={onSave}
          disabled={!isValid}
          className="block mx-auto mt-4"
          size="large"
          type="primary"
          icon={<ThunderboltOutlined />}
        >
          {isEdit ? 'Mettre à jour la variable globale' : 'Créer la variable globale'}
        </Button>
      )}
    </>
  );
};

export default GlobalVariableForm;

import { message, notification } from 'antd/lib';
import { always, cond, equals, is, prop, T } from 'ramda';
import { isTrue } from 'ramda-adjunct';
import { SCOPE_OFFER } from '../_CONST';

export const successMessage = (type, action, apostrophe, feminine, plural) => {
  const headMsg = cond([
    [isTrue, always('L\'')],
    [() => isTrue(plural), always('Les ')],
    [() => isTrue(feminine), always('La ')],
    [T, always('Le ')]
  ])(apostrophe);
  const messageFormatted = isTrue(plural) ? `${headMsg}${type}s ont bien été ${action}s.` : `${headMsg}${type} a bien été ${action}.`;
  return message.success(`${messageFormatted}`);
};
export const errorMessage = () => message.error('Une erreur est survenue, veuillez réessayer.');

export const deleteMutationMessage = (ok, error, type, cb, apostrophe, feminine, plural) => {
  if (ok && !error) {
    successMessage(type, 'supprimé', apostrophe, feminine, plural);
  } else {
    errorMessage();
  }
  if (cb && is(Function, cb)) cb(ok, error);
};

export const updateMutationMessage = (ok, error, type, cb, reload) => {
  if (ok && !error) {
    successMessage(`Le contenu du ${type}`, 'mise à jour');
    if (reload) window.location.reload();
  } else {
    errorMessage();
  }
  if (cb && is(Function, cb)) cb(ok, error);
};

export const callbackSave = (type, successMessage, history, scope, state, offerId) => (ok, error) => {
  if (ok && !error) {
    message.success(successMessage);
    if (equals(scope, SCOPE_OFFER) || equals(prop('scope', state), SCOPE_OFFER)) {
      history.push(`/offre/${offerId || prop('offerId', state)}/edition`);
    } else {
      history.push(`/equipe/${type}`);
    }
  } else {
    if (equals('name_already_exist', error)) {
      message.error('Le nom indiqué existe déjà.');
    } else {
      errorMessage();
    }
  }
};

export const successNotification = (message, description = null) => notification.success({ message, description });

export const errorNotification = () => notification.error({ message: 'Une erreur est survenue.', description: 'Veuillez réessayer.' });

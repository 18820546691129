import React from 'react';
import { Spin } from 'antd/lib';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const Loading = ({
  fullPage,
  children,
  spinning = true,
  style = {}
}) => fullPage
  ? (
    <Spin
      indicator={<LoadingOutlined className="text-6xl" spin/>}
      className="w-full h-screen leading-[100vh]"
    />
  )
  : (
    <Spin
      className="w-full"
      // eslint-disable-next-line no-restricted-syntax
      style={style}
      indicator={<LoadingOutlined className="text-2xl" spin/>}
      spinning={spinning}
    >
      {children}
    </Spin>
  );

Loading.propTypes = {
  fullPage: PropTypes.bool,
  spinning: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ])
};

export default Loading;

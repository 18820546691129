import React, { useMemo } from 'react';
import { assoc, compose, dissoc } from 'ramda';
import { IFRAME_PDF_HEIGHT, IFRAME_PDF_WIDTH } from '../../../_CONST';
import useFrameSync from '../../../hooks/useFrameSync';

const FrameResult = ({ fileId, isImg, pdfFileInfo, isScrollSynchronized, frameRef, otherFrameRef, showDiff, toCompare }) => {
  const IS_CHROME_BROWSER = navigator.userAgent.includes('Chrome');
  const { iframeHeight, sourceElement, targetElement, fileDataReturned, synchronizeScrolling } = useFrameSync({
    fileId, frameRef, otherFrameRef, showDiff
  });

  const { fileName, IMAGE_SRC, pdfUrl } = fileDataReturned;

  const propsBasePdfFile = useMemo(() => {
    let value = {
      title: fileName,
      height: `${IFRAME_PDF_HEIGHT}px`,
      src: `${pdfUrl}#zoom=75`,
      width: IFRAME_PDF_WIDTH
    };
    if (toCompare) {
      value = compose(
        assoc('className', 'w-full'),
        dissoc('width')
      )(value);
    }
    return value;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toCompare, fileDataReturned]);

  if (pdfFileInfo) {
    return (
      <div className="inline-flex w-full justify-center items-center mt-2 space-x-6 ">
        {IS_CHROME_BROWSER ? (
          <embed {...pdfFileInfo} width="100%"/>
        ) : (
          <iframe {...pdfFileInfo} width="100%"/>
        )}
      </div>
    );
  }

  if (isImg) {
    return (
      <div className="inline-flex w-full justify-center items-center space-x-6">
        <div className="flex flex-col">
          <h2 className="text-sm text-flexibranche-lightblue mb-2 mt-8">
            {fileName && fileName.replace('diff', 'page').replace('.png', '')}
          </h2>
          <img
            src={IMAGE_SRC}
            alt="img"
            // eslint-disable-next-line no-restricted-syntax
            style={{ width: IFRAME_PDF_WIDTH }}
            className="h-auto shadow-frame"
          />
        </div>
      </div>
    );
  }

  return (
    <div
      ref={frameRef} className="trames-iframe-container w-full" id={`trames-iframe-container-${fileId}`}
      onScroll={() => { isScrollSynchronized && synchronizeScrolling(sourceElement, targetElement); }}
    >
      {
        IS_CHROME_BROWSER
          ? (
            <embed
              {...propsBasePdfFile}
              // eslint-disable-next-line no-restricted-syntax
              style={{ height: `${iframeHeight}px` }}
            />
          )
          : (
            <iframe
              {...propsBasePdfFile}
              // eslint-disable-next-line no-restricted-syntax
              style={{ height: `${iframeHeight}px` }}
            />
          )}
    </div>
  );
};

export default FrameResult;

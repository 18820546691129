import { cond, equals, gt, length, not, pick, prop, sum } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { requestSubscription, useRelayEnvironment } from 'react-relay';
import { isNilOrEmpty, notEqual } from 'ramda-adjunct';
import GenerateComparePdfMutation from '../_graphql/mutations/documents/GenerateComparePdfMutation';
import { FinishedComparisonsSubscriptionQuery } from '../_graphql/subscriptions/comparePDF/FinishedComparisonsSubscription';

const useCompare = ({
  userId,
  frameIds,
  setRefetch,
  useModal = true
}) => {
  const [loadingOnCompareAll, setLoadingOnCompareAll] = useState(false);
  const [loadingOnCompareOnRow, setLoadingOnCompareOnRow] = useState(false);
  const [loadingOnCompareOnSelected, setLoadingOnCompareOnSelected] = useState(false);
  const [nbComparisons, setNbComparisons] = useState(0);
  const [statusComparisons, setStatusComparisons] = useState({
    nbComparisonsDone: 0,
    passed: 0,
    failed: 0,
    error: 0
  });
  const [progress, setProgress] = useState(0);
  const [openModalVisible, setIsModalVisible] = useState(false);
  const environment = useRelayEnvironment();
  const [elapsedTime, setElapsedTime] = useState(0);
  const elapsedTimeInterval = useRef(null);
  const startTimeRef = useRef(null);

  useEffect(() => {
    const nbComparisonsDone = prop('nbComparisonsDone', statusComparisons);
    const totalComparisonsDone = sum([prop('passed', statusComparisons), prop('failed', statusComparisons), prop('error', statusComparisons)]);

    if (gt(nbComparisons, 0)) {
      if (equals(totalComparisonsDone, nbComparisons)) {
        setProgress(100);
        clearInterval(elapsedTimeInterval.current);
      } else {
        setProgress((nbComparisonsDone / nbComparisons) * 100);
      }
    } else {
      setProgress(0);
      clearInterval(elapsedTimeInterval.current);
      setElapsedTime(0);
    }
  }, [statusComparisons, nbComparisons]);

  useEffect(() => {
    if (!openModalVisible) {
      setProgress(0);
      setNbComparisons(0);
      setStatusComparisons({
        nbComparisonsDone: 0,
        passed: 0,
        failed: 0,
        error: 0
      });
      clearInterval(elapsedTimeInterval.current);
      setElapsedTime(0);
    }
  }, [openModalVisible]);

  const subscribeToFinishedComparisons = () => {
    const finishedComparisons = requestSubscription(environment, {
      subscription: FinishedComparisonsSubscriptionQuery,
      variables: { filter: { userId, itemIds: frameIds } },
      onNext: (response) => {
        if (response.finishedComparisonsSubscription) {
          const { comparisonsDone, errorCount, failedCount, passedCount } = response.finishedComparisonsSubscription;
          setStatusComparisons({
            nbComparisonsDone: comparisonsDone,
            passed: passedCount,
            failed: failedCount,
            error: errorCount
          });
        }
      },
      onError: (error) => {
        console.error('Subscription error:', error);
        setTimeout(() => subscribeToFinishedComparisons(), 30000);
      },
      updater: (store, { finishedComparisonsSubscription }) => {
        const { frameId, status, comparisonId } = pick(['frameId', 'status', 'comparisonId'], finishedComparisonsSubscription);
        if (useModal || notEqual(status, 'waiting')) {
          return;
        }
        const document = store.get(frameId);
        const comparisons = document.getLinkedRecords('comparisons') || [];
        const record = store.get(comparisonId);
        if (isNilOrEmpty(record)) {
          const comparison = store.create(comparisonId, 'ComparisonGeneratePdfResult');
          comparison.setValue(comparisonId, 'id');
          comparison.setValue((new Date()).toString(), 'when');
          comparison.setValue(status, 'globalStatusResult');
          document.setLinkedRecords([comparison, ...comparisons], 'comparisons');
        }
      }
    });

    return finishedComparisons;
  };

  useEffect(() => {
    if (openModalVisible || not(useModal)) {
      const finishedComparisons = subscribeToFinishedComparisons();
      return () => finishedComparisons.dispose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment, openModalVisible, useModal]);

  const updateLoading = (type, bool) => cond([
    [equals('all'), () => setLoadingOnCompareAll(bool)],
    [equals('row'), () => setLoadingOnCompareOnRow(bool)],
    [equals('selected'), () => setLoadingOnCompareOnSelected(bool)]
  ])(type);

  const onCompare = (type, ids) => {
    setIsModalVisible(true);
    updateLoading(type, true);
    setNbComparisons(length(ids));

    GenerateComparePdfMutation({ ids }, () => {
      updateLoading(type, false);
      !!setRefetch && setRefetch(true);
    });

    startTimeRef.current = Date.now();
    elapsedTimeInterval.current = setInterval(() => {
      // Calculate elapsed time relative to actual time, because setInterval alone is not precise and lags
      const currentTime = Date.now();
      const timeElapsedInSeconds = (currentTime - startTimeRef.current) / 100;
      setElapsedTime((timeElapsedInSeconds / 10).toFixed(2));
    }, 100);
  };

  return {
    loadingOnCompareAll,
    loadingOnCompareOnRow,
    loadingOnCompareOnSelected,
    nbComparisons,
    progress,
    openModalVisible,
    setIsModalVisible,
    statusComparisons,
    onCompare,
    elapsedTime
  };
};

export default useCompare;

import { commitMutation,
  graphql } from 'react-relay';
import environment from '../../.';
import { onErrorMutation } from '../common/common';

const mutation = graphql`
  mutation CreateGlobalVariableMutation($globalVariable: GlobalVariableInput!) {
    createGlobalVariable(globalVariable: $globalVariable) {
      ok
      error
      globalVariableId
      name
    }
  }
`;

export default ({
  globalVariable
}, done) => {
  const variables = { globalVariable };

  commitMutation(
    environment(),
    {
      mutation,
      variables,
      onCompleted: ({ createGlobalVariable }) => {
        const {
          ok,
          error,
          globalVariableId,
          name
        } = createGlobalVariable;
        done(ok, error, { globalVariableId, name });
      },
      onError: error => onErrorMutation(error, done)
    }
  );
};

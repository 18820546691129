/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BaseSubscriptionInput = {|
  itemIds?: ?$ReadOnlyArray<?string>,
  userId: string,
|};
export type FinishedRemoveOfferSubscriptionVariables = {|
  filter: BaseSubscriptionInput
|};
export type FinishedRemoveOfferSubscriptionResponse = {|
  +finishedRemoveOfferSubscription: ?{|
    +id: ?string,
    +step: ?number,
    +documents: ?boolean,
    +rawDocuments: ?boolean,
    +widgets: ?boolean,
    +tags: ?boolean,
    +globalVariables: ?boolean,
    +frames: ?boolean,
  |}
|};
export type FinishedRemoveOfferSubscription = {|
  variables: FinishedRemoveOfferSubscriptionVariables,
  response: FinishedRemoveOfferSubscriptionResponse,
|};
*/


/*
subscription FinishedRemoveOfferSubscription(
  $filter: BaseSubscriptionInput!
) {
  finishedRemoveOfferSubscription(filter: $filter) {
    id
    step
    documents
    rawDocuments
    widgets
    tags
    globalVariables
    frames
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "RemoveOfferResult",
    "kind": "LinkedField",
    "name": "finishedRemoveOfferSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "step",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documents",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawDocuments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "widgets",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tags",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "globalVariables",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "frames",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FinishedRemoveOfferSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FinishedRemoveOfferSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "948dd8c53ec109a88413784dac002eeb",
    "id": null,
    "metadata": {},
    "name": "FinishedRemoveOfferSubscription",
    "operationKind": "subscription",
    "text": "subscription FinishedRemoveOfferSubscription(\n  $filter: BaseSubscriptionInput!\n) {\n  finishedRemoveOfferSubscription(filter: $filter) {\n    id\n    step\n    documents\n    rawDocuments\n    widgets\n    tags\n    globalVariables\n    frames\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f0a1fcf53605b5c32c86fea9c7301465';

module.exports = node;

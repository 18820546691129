import { Button, Input, message, Select } from 'antd';
import { assocPath, assoc, path, prop, or, and, map, gt } from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect, useState } from 'react';
import { requestSubscription, useRelayEnvironment } from 'react-relay';
import { useHistory } from 'react-router-dom';
import { ContentBlock } from '../../components';
import SwitchByExisting from '../../components/SwitchByExisting';
import Title from '../../components/Title';
import useKeyPressEnter from '../../hooks/useKeyPressEnter';
import { errorMessage, successMessage } from '../../utils/messageMutation';
import CreateOfferFromExistingMutation from '../../_graphql/mutations/offers/CreateOfferFromExistingMutation';
import { FinishedDuplicateOfferSubscriptionQuery } from '../../_graphql/subscriptions/duplicateOffer/FinishedDuplicateOfferSubscription';
import CreateOfferMutation from '../../_graphql/mutations/offers/CreateOfferMutation';
import { QOffers } from '../../_graphql/queries';
import { removeNull } from '../helpers/removeNull';
import ModalDuplicateOffer from '../../components/ModalDuplicateOffer';
import withUser from '../../withUser';

const useCreateOffer = (openStatusModal, onError) => {
  const [state, setState] = useState({
    values: {
      offerName: '',
      fromOfferId: undefined,
      isCopyOffer: false
    },
    loading: false
  });

  const setValue = (k, v) => setState(assocPath(['values', k], v));
  const history = useHistory();

  const onCreate = () => {
    setState(assoc('loading', true));
    const isCopyOffer = path(['values', 'isCopyOffer'], state);
    if (openStatusModal && isCopyOffer) openStatusModal();

    const callback = (ok, error) => {
      setState(assoc('loading', false));

      if (ok && !error) {
        successMessage('offre', 'créée avec succès', true);
        if (!isCopyOffer) history.push('/offre');
      } else {
        errorMessage();
        !!onError && onError();
      }
    };

    if (!isCopyOffer) {
      CreateOfferMutation(prop('values', state), callback);
    } else {
      setState(assoc('loading', true));
      message.info('Offre en cours de création, veuillez patienter...');
      CreateOfferFromExistingMutation(prop('values', state), callback);
    }
  };

  const buttonIsDisabled = or(
    isNilOrEmpty(path(['values', 'offerName'], state)),
    and(path(['values', 'isCopyOffer'], state), isNilOrEmpty(path(['values', 'fromOfferId'], state)))
  );

  useKeyPressEnter(onCreate, !buttonIsDisabled, prop('values', state));

  return {
    ...state,
    onCreate,
    setValue,
    buttonIsDisabled
  };
};

const CreateOffer = ({ user }) => {
  const environment = useRelayEnvironment();

  const [progress, setProgress] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [errorDuplicateOffer, setErrorDuplicateOffer] = useState(false);
  const [statusDuplicateOffer, setStatusDuplicateOffer] = useState({
    step: 0,
    widgets: false,
    documents: false,
    rawDocuments: false,
    tags: false,
    globalVariables: false,
    folders: false,
    frames: false
  });

  const openStatusModal = () => setIsOpen(true);
  const onError = () => setErrorDuplicateOffer(true);

  const {
    values,
    loading,
    setValue,
    onCreate,
    buttonIsDisabled
  } = useCreateOffer(openStatusModal, onError);

  useEffect(() => {
    const step = prop('step', statusDuplicateOffer);
    if (gt(step, 0)) {
      setProgress(Math.floor(step * 100));
    } else {
      setProgress(0);
    }
  }, [statusDuplicateOffer]);

  useEffect(() => {
    if (!isOpen) {
      setProgress(0);
      setStatusDuplicateOffer({
        step: 0,
        widgets: false,
        documents: false,
        rawDocuments: false,
        tags: false,
        globalVariables: false,
        folders: false,
        frames: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const fromOfferId = prop('fromOfferId', values);
      const finishedDuplicateOffer = requestSubscription(environment, {
        subscription: FinishedDuplicateOfferSubscriptionQuery,
        variables: { filter: { userId: prop('userId', user), itemIds: [fromOfferId] } },
        onNext: (response) => {
          if (response.finishedDuplicateOfferSubscription) {
            const { step, widgets, documents, rawDocuments, tags, globalVariables, folders, frames } = response.finishedDuplicateOfferSubscription;
            setStatusDuplicateOffer({
              step,
              widgets,
              documents,
              rawDocuments,
              tags,
              globalVariables,
              folders,
              frames
            });
          }
        },
        onError: (error) => {
          console.error('Subscription error:', error);
        }
      });

      return () => finishedDuplicateOffer.dispose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment, isOpen, user]);

  return (
    <QOffers>
      {offers => (
        <>
          <Title title="Création d'une offre" backTo="/offre" />
          <ContentBlock>
            <h4 className="font-bold mb-4">{'Nom de l\'offre'}</h4>
            <Input
              placeholder="Nom de l'offre"
              value={prop('offerName', values)}
              onChange={e => setValue('offerName', e.target.value)}
            />
            {isNotNilOrEmpty(removeNull(offers)) && (
              <SwitchByExisting
                checked={prop('isCopyOffer', values)}
                onChange={checked => setValue('isCopyOffer', checked)}
                onClick={() => setValue('isCopyOffer', !prop('isCopyOffer', values))}
                label="Créer à partir d'une offre existante"
                fromValue={prop('fromOfferId', values)}
                onSelect={v => setValue('fromOfferId', v)}
                placeholder="Offre à dupliquer"
                options={map(({ offerName, offerId, version }) => {
                  const title = `${offerName} - ${prop('major', version)}.${prop('minor', version)}`;
                  return (
                    <Select.Option
                      key={offerId}
                      value={offerId}
                      title={title}
                    >
                      {title}
                    </Select.Option>
                  );
                })(removeNull(offers))}
              />
            )}
            <Button
              loading={loading}
              disabled={buttonIsDisabled}
              onClick={onCreate}
              type="primary"
              size="large"
              className="block mt-6"
            >
              Créer la nouvelle offre
            </Button>
          </ContentBlock>
          <ModalDuplicateOffer
            open={isOpen}
            setIsOpen={setIsOpen}
            statusDuplicateOffer={statusDuplicateOffer}
            progress={progress}
            redirectTo="/offre"
            errorDuplicateOffer={errorDuplicateOffer}
          />
        </>
      )}
    </QOffers>
  );
};

export default withUser(CreateOffer);

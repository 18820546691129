import React from 'react';
import { prop } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import { Route, withRouter } from 'react-router-dom';
import withUser from '../../withUser';
import ElementsRoutes from '../elements/Routes';
import CreateGroup from '../groups/CreateGroup';
import EditGroup from '../groups/EditGroup';
import { home, RoutesBreadcrumbChildren } from '../helpers/breadCrumb';
import MyTeam from './components/MyTeam';
import NewTeam from './components/NewTeam';

const widgets = ['/equipe/widgets', 'Mes widgets'];
const previewWidget = ({ widgetId }) => [`/equipe/widgets/${widgetId}/preview`, 'Prévisualiser un widget'];
const widgetHistory = ({ widgetId }) => [`/equipe/widgets/${widgetId}/historique`, 'Historique'];

const styles = ['/equipe/styles', 'Mes styles'];
const createStyle = ['/equipe/styles/creer', 'Créer'];
const editStyle = ({ styleId }) => [`/equipe/styles/${styleId}/edition`, 'Éditer'];
const styleHistory = ({ styleId }) => [`/equipe/styles/${styleId}/historique`, 'Historique'];

const colors = ['/equipe/couleurs', 'Mes couleurs'];
const createColor = ['/equipe/couleurs/creer', 'Créer'];
const editColor = ({ colorId }) => [`/equipe/couleurs/${colorId}/edition`, 'Éditer'];
const tags = ['/equipe/tags', 'Mes tags'];
const createTag = ['/equipe/tags/creer', 'Créer'];
const editTag = ({ tagId }) => [`/equipe/tags/${tagId}/edition`, 'Éditer'];
const font = ['/equipe/police', 'Ma police par défaut'];

const myTeam = ['/equipe', 'Mon équipe'];
const createGroup = ['/groupes/creer', 'Créer un groupe'];
const editGroup = ({ groupId }) => [`/groupes/${groupId}`, 'Gestion du groupe'];

const routes = [
  ['/equipe/widgets/:widgetId/preview', [home, myTeam, widgets, previewWidget]],
  ['/equipe/widgets/:widgetId/historique', [home, myTeam, widgets, widgetHistory]],
  ['/equipe/widgets', [home, myTeam, widgets]],

  ['/equipe/tags/creer', [home, myTeam, tags, createTag]],
  ['/equipe/tags/:tagId/edition', [home, myTeam, tags, editTag]],
  ['/equipe/tags', [home, myTeam, tags]],

  ['/equipe/couleurs/creer', [home, myTeam, colors, createColor]],
  ['/equipe/couleurs/:colorId/edition', [home, myTeam, colors, editColor]],
  ['/equipe/couleurs', [home, myTeam, colors]],

  ['/equipe/police', [home, myTeam, font]],

  ['/equipe/styles/creer', [home, myTeam, styles, createStyle]],
  ['/equipe/styles/:styleId/historique', [home, myTeam, styles, editStyle, styleHistory]],
  ['/equipe/styles/:styleId/edition', [home, myTeam, styles, editStyle]],
  ['/equipe/styles', [home, myTeam, styles]],

  ['/equipe/groupes/creer', [home, myTeam, createGroup]],
  ['/equipe/groupes/:groupId', [home, myTeam, editGroup]],
  ['/equipe', [home, myTeam]]
];

const Team = ({ userTeam, match }) => {
  const hasTeam = isNotNil(prop('teamId', userTeam));
  const matchUrl = prop('url', match);
  return (
    <>
      {hasTeam ? (
        <RoutesBreadcrumbChildren routes={routes}>
          <Route exact path={matchUrl} component={MyTeam}/>
          <Route path={`${match.path}`} component={ElementsRoutes} />

          <Route path={`${matchUrl}/groupes/creer`} component={CreateGroup}/>
          <Route path={`${matchUrl}/groupes/:groupId/gestion`} component={EditGroup}/>
        </RoutesBreadcrumbChildren>
      ) : (
        <NewTeam/>
      )}
    </>
  );
};

export default withRouter(withUser(Team));

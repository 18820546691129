import React, { useState } from 'react';
import { prop, gt, equals, length, sum } from 'ramda';
import { PlusOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { Title, Actions, LinkButton, Section } from '../../components';
import withUser from '../../withUser';
import QGetAllFrames from '../../_graphql/queries/documents/QGetAllFrames';
import useCompare from '../../hooks/useCompare';
import ModalLoading from '../../components/ModalLoading';
import FramesList from './FramesList';

const MyFrames = ({ userIsAdmin, userTeam, user }) => {
  const [refetch, setRefetch] = useState(false);
  const [selectedFrames, setSelectedFrames] = useState([]);
  const {
    loadingOnCompareAll,
    loadingOnCompareOnRow,
    loadingOnCompareOnSelected,
    onCompare,
    nbComparisons,
    progress,
    openModalVisible,
    setIsModalVisible,
    statusComparisons,
    elapsedTime
  } = useCompare({
    userId: prop('userId', user),
    frameIds: selectedFrames
  });
  const areManyComparisons = nbComparisons > 1;
  const hasFailedComparison = gt(prop('failed', statusComparisons), 0);
  const hasErrorComparison = gt(prop('error', statusComparisons), 0);
  const totalComparisonsDone = sum([prop('passed', statusComparisons), prop('failed', statusComparisons), prop('error', statusComparisons)]);

  return (
    <>
      <ModalLoading
        visible={openModalVisible}
        onClick={() => {
          setIsModalVisible(false);
          setRefetch(true);
        }}
        progress={progress}
        elapsedTime={elapsedTime}
        infoLoading={(
          <Section
            className="shadow-none"
            classNameTitle="text-flexidocs-turquoise"
            title="Compte rendu de vos comparaisons"
          >
            <div className="flex justify-between">
              <p>Comparaisons sans différences</p>
              <p>{prop('passed', statusComparisons)}</p>
            </div>
            <div className="flex justify-between">
              <p>Différences détectées</p>
              <p>{prop('failed', statusComparisons)}</p>
            </div>
            <div className="flex justify-between">
              <p>Comparaisons échouées</p>
              <p>{prop('error', statusComparisons)}</p>
            </div>
            <div className="flex justify-between">
              <strong>Total comparaisons réalisées</strong>
              <p>{totalComparisonsDone}</p>
            </div>
            {equals(100, progress) && hasFailedComparison && (
              <Alert
                type="warning"
                message={(
                  <p>
                    {areManyComparisons ? 'Les comparaisons ont rendues' : 'La comparaison a rendue'} des différences. Vous pouvez les consulter en allant sur les informations de générations.
                  </p>
                )}
              />
            )}
            {equals(100, progress) && hasErrorComparison && (
              <Alert
                type="error"
                message={(
                  <p>
                    {`Impossible de générer ${length(prop('error', statusComparisons)) > 1 ? 'plusieurs' : 'une'} comparaison${length(prop('error', statusComparisons)) > 1 ? 's' : ''}, veuillez contacter l'équipe Flexidocs`}
                  </p>
                )}
              />
            )}
          </Section>
        )}
        textProgression={`${totalComparisonsDone} sur ${nbComparisons}`}
      />
      <QGetAllFrames
        args={{
          teamId: prop('teamId', userTeam), refetch, setRefetch
        }}
      >
        {({ allFrames }) => (
          <>
            <Actions
              alignButtonEndRight
              title={(
                <Title
                  title="mes trames"
                  icon="/images/icon-comparer@3x.png"
                  iconSize={[20, 22]}
                  iconOffset={-2}
                  filter="invert(100%)"
                />
              )}
              buttons={(
                <LinkButton
                  to="/trames/creer"
                  size="large"
                  icon={<PlusOutlined />}
                  disabled={loadingOnCompareOnRow || loadingOnCompareOnSelected}
                >
                  Créer une trame
                </LinkButton>
              )}
            />

            <FramesList
              userIsAdmin={userIsAdmin}
              onCompare={onCompare}
              loadingOnCompare={loadingOnCompareAll || loadingOnCompareOnSelected}
              loadingOnCompareOnRow={loadingOnCompareOnRow}
              frames={allFrames}
              setRefetch={setRefetch}
              setSelectedFrames={setSelectedFrames}
            />
          </>
        )}
      </QGetAllFrames>
    </>
  );
};

export default withUser(MyFrames);

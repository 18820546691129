import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { map, isEmpty, propOr, prop } from 'ramda';
import { Tabs } from 'antd';
import { Section } from '../../components';
import Loading from '../../components/Loading';
import QGetFrame from '../../_graphql/queries/documents/QGetFrame';
import { QFile } from '../../_graphql/queries/documents/QFile';
import { IFRAME_PDF_HEIGHT } from '../../_CONST';
import { getFileName } from '../../utils/getFilename';
import { base64toBlob } from '../helpers/base64ToBlob';
import withUser from '../../withUser';
import { verifyRouteAccess } from '../helpers/routeAccess';
import FrameForm from './components/FrameForm';
import FrameResult from './components/FrameResult';
import HistoryComparisonsTable from './HistoryComparisonsTable';

const Frame = ({ userTeam, user }) => {
  const { frameId } = useParams();
  const [refetch, setRefetch] = useState(false);
  return (
    <QGetFrame
      args={{
        frameId, refetch, setRefetch
      }}
    >
      {({ getFrame }) => {
        const accessDenied = verifyRouteAccess(userTeam, getFrame, 'cette trame');
        if (accessDenied) return accessDenied;
        const { pdfIds } = getFrame;

        return (
          <>
            <FrameForm
              frame={getFrame}
              onEdit
              setRefetch={setRefetch}
            />
            <HistoryComparisonsTable values={getFrame} userId={prop('userId', user)} setRefetch={setRefetch} />
            {isEmpty(pdfIds) && refetch ? (
              <Loading />
            ) : (
              <Section
                className="section-doc-frame shadow-none mt-8"
                title="Documents"
              >
                <Tabs>
                  {map((fileId) => (
                    <Tabs.TabPane
                      key={fileId}
                      tab={(
                        <QFile args={{ fileId }}>
                          {({ getFile }) => (
                            <div className="flex flex-row items-center">
                              <h3 className="text-xl font-bold text-flexibranche-lightblue mb-0">
                                {getFileName(prop('fileName', getFile))}
                              </h3>
                            </div>
                          )}
                        </QFile>
                      )}
                    >
                      <QFile args={{ fileId }}>
                        {({ getFile }) => {
                          const base64PdfString = propOr('', 'fileBase64', getFile);
                          const pdfBlob = base64toBlob(base64PdfString, 'application/pdf');
                          const pdfUrl = URL.createObjectURL(pdfBlob);

                          const pdfFileInfo = {
                            title: getFileName(prop('fileName', getFile)),
                            height: `${IFRAME_PDF_HEIGHT}px`,
                            src: pdfUrl
                          };
                          return <FrameResult pdfFileInfo={pdfFileInfo} key={fileId} />;
                        }}
                      </QFile>
                    </Tabs.TabPane>
                  ))(pdfIds)}
                </Tabs>
              </Section>
            )}
          </>
        );
      }}
    </QGetFrame>
  );
};

export default withUser(Frame);

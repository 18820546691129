import React from 'react';
import { Modal } from 'antd';
import { SCOPE_OFFER } from '../../../../_CONST';
import GlobalVariableForm from '../globalVariable/GlobalVariableForm';

const CreateGlobalVariableModal = ({
  isOpenCreateGlobalVariableModal,
  setIsOpenCreateGlobalVariableModal,
  offerId,
  setGlobalVariableCreated
}) => {
  const onCancel = () => setIsOpenCreateGlobalVariableModal(null);
  return (
    <Modal
      visible={isOpenCreateGlobalVariableModal}
      footer={null}
      onCancel={onCancel}
      width="90%"
    >
      <GlobalVariableForm
        scope={SCOPE_OFFER}
        offerId={offerId}
        modalMode
        setIsOpenCreateGlobalVariableModal={setIsOpenCreateGlobalVariableModal}
        setGlobalVariableCreated={setGlobalVariableCreated}
      />
    </Modal>
  );
};

export default CreateGlobalVariableModal;

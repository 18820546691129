import React from 'react';
import { compose, isNil, reject, defaultTo, prop } from 'ramda';
import { useHistory } from 'react-router-dom';
import { Popconfirm, Table, Button } from 'antd';
import QColors from '../../../_graphql/queries/documents/QColors';
import { Section, Title, Actions } from '../../../components';
import { Edit, Trash } from '../../../components/icons';
import DeleteColorMutation from '../../../_graphql/mutations/documents/DeleteColorMutation';
import { errorMessage } from '../../../utils/messageMutation';
import TableDates from '../../docs/components/tag/TableDates';
import PreviewColor from './components/PreviewColor';

const Colors = () => {
  const history = useHistory();

  const deleteColor = colorId => {
    DeleteColorMutation({ colorId }, (ok, error) => {
      if (!ok || error) {
        errorMessage();
      }
    });
  };

  return (
    <>
      <Actions
        title={<Title title="Les couleurs de mon équipe" backTo="/equipe" />}
        buttons={(
          <Button
            onClick={() => history.push('/equipe/couleurs/creer')}
            type="primary"
            size="large"
          >
            Créer une couleur
          </Button>
        )}
      />
      <Section>
        <QColors>
          {colors => {
            colors = compose(
              reject(isNil),
              defaultTo([])
            )(colors);

            return (
              <Table
                dataSource={colors}
                rowKey="id"
                pagination={false}
                size="small"
              >
                <Table.Column
                  title="Nom"
                  dataIndex="name"
                  width={250}
                  defaultSortOrder="ascend"
                  sorter={(a, b) => prop('name', a).toLowerCase().localeCompare(prop('name', b).toLowerCase())}
                  render={value => <span>{value}</span>}
                />

                <Table.Column
                  title="Couleur"
                  dataIndex="value"
                  width={250}
                  render={value => (
                    <PreviewColor
                      color={value}
                    />
                  )}
                />

                {TableDates}

                <Table.Column
                  title=""
                  dataIndex="id"
                  render={(colorId) => {
                    return (
                      <div className="flex justify-end text-lg gap-2">
                        <Edit
                          className="grey"
                          onClick={() => history.push(`/equipe/couleurs/${colorId}/edition`)}
                        />

                        <Popconfirm
                          title="Êtes-vous sûr de vouloir supprimer cette couleur ?"
                          onConfirm={() => deleteColor(colorId)}
                        >
                          <Trash className="grey" />
                        </Popconfirm>
                      </div>
                    );
                  }}
                />
              </Table>
            );
          }}
        </QColors>
      </Section>
    </>
  );
};

export default Colors;

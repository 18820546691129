import { HistoryOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { path, prop } from 'ramda';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CreateVersion, StatusActions } from '../../../../components';
import Review from '../../../../components/Review';

const StyleEditActions = ({ style, userIsValidator, userId }) => {
  const history = useHistory();

  return (
    <div>
      <Button
        size="small"
        onClick={() => history.push(`/equipe/styles/${prop('id', style)}/historique`)}
        icon={<HistoryOutlined/>}
      >
        Historique du style
      </Button>

      <CreateVersion
        versionId={path(['version', 'versionId'], style)}
        userCanCreateVersion={userIsValidator}
        redirect="/equipe/styles/:itemId/edition"
        version={prop('version', style)}
        userId={userId}
      />

      <StatusActions
        item={style}
        draftLabel="Passer le style en mode édition"
        reviewLabel="Passer le style en revue"
        userCanValidate={userIsValidator}
      />

      <Review
        item={style}
        userCanValidate={userIsValidator}
      />
    </div>
  );
};

export default StyleEditActions;

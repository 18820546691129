import React, { useEffect, useState, useRef } from 'react';
import { addIndex, map, propEq, replace, __, reject, propSatisfies, isNil, either, isEmpty, prop, compose, head, propOr, groupBy, path, reverse, keys, gt, equals } from 'ramda';
import { CaretRightFilled, CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Collapse, Timeline, Popconfirm, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { requestSubscription, useRelayEnvironment } from 'react-relay';
import { Dot, Section } from '..';
import UpdateVersionStatusMutation from '../../_graphql/mutations/versions/UpdateVersionStatusMutation';
import RemoveVersionMutation from '../../_graphql/mutations/versions/RemoveVersionMutation';
import { getStatusLabel } from '../../lib/status';
import { errorMessage, successMessage } from '../../utils/messageMutation';
import RemoveCacheMutation from '../../_graphql/mutations/RemoveCacheMutation';
import { FinishedRemoveOfferSubscriptionQuery } from '../../_graphql/subscriptions/removeOffer/FinishedRemoveOfferSubscription';
import ModalLoading from '../ModalLoading';
import VersionsTreeItem from './VersionsTreeItem';

const mapIndexed = addIndex(map);

const timelineItemStyle = { paddingBottom: '10px' };

const panelItemStyle = { borderBottom: '1px solid #EBEAEA' };

const VersionsTree = ({
  items,
  userCanEdit,
  editLinkPattern,
  suppressionLabel,
  archiveLabel,
  nameField = 'name',
  setRefetch,
  userId
}) => {
  const [openModalVisible, setIsModalVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [statusRemoveOffer, setStatusRemoveOffer] = useState({
    step: 0,
    documents: false,
    rawDocuments: false,
    widgets: false,
    tags: false,
    globalVariables: false,
    frames: false
  });
  const [errorRemoveOffer, setErrorRemoveOffer] = useState(false);
  const environment = useRelayEnvironment();
  const selecteItemRef = useRef(null);

  useEffect(() => {
    const step = prop('step', statusRemoveOffer);
    if (gt(step, 0)) {
      setProgress(step * 100);
    } else {
      setProgress(0);
    }
  }, [statusRemoveOffer]);

  useEffect(() => {
    if (!openModalVisible) {
      setProgress(0);
      setStatusRemoveOffer({
        step: 0,
        documents: false,
        rawDocuments: false,
        widgets: false,
        tags: false,
        globalVariables: false,
        frames: false
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalVisible]);

  useEffect(() => {
    if (openModalVisible) {
      const itemId = selecteItemRef.current;
      const finishedRemoveOffer = requestSubscription(environment, {
        subscription: FinishedRemoveOfferSubscriptionQuery,
        variables: { filter: { userId, itemIds: [itemId] } },
        onNext: (response) => {
          if (response.finishedRemoveOfferSubscription) {
            const { step, documents, rawDocuments, widgets, tags, globalVariables, frames } = response.finishedRemoveOfferSubscription;
            setStatusRemoveOffer({
              step,
              documents,
              rawDocuments,
              widgets,
              tags,
              globalVariables,
              frames
            });
          }
        },
        onError: (error) => {
          console.error('Subscription error:', error);
        }
      });

      return () => finishedRemoveOffer.dispose();
    }
  }, [environment, openModalVisible, userId]);

  const onArchive = (versionId, itemId) => () => {
    UpdateVersionStatusMutation({
      versionId, status: 'archived', itemId
    }, (ok, error) => {
      if (ok && !error) {
        successMessage('version', 'archivée', false, true);
      } else {
        errorMessage();
      }
    });
  };

  const onRemove = (versionId, itemId) => () => {
    selecteItemRef.current = itemId;
    setIsModalVisible(true);
    RemoveVersionMutation({ versionId }, (ok, error) => {
      if (ok && !error) {
        RemoveCacheMutation({ key: `documents-${itemId}` }, () => {});
        RemoveCacheMutation({ key: `widgets-${itemId}` }, () => {});
        sessionStorage.setItem('frameSelectedOffer', JSON.stringify({}));
      } else {
        setErrorRemoveOffer(error);
      }
    });
  };

  const statusIs = (status, version) => propEq('status', status, version);
  const buildEditLink = replace(':itemId', __, editLinkPattern);

  items = reject(propSatisfies(isNil, 'version'))(items);

  const statusItemRemoved = (type) => {
    if (statusRemoveOffer[type]) {
      return <CheckCircleFilled className="!text-green-400"/>;
    } else {
      return <LoadingOutlined />;
    }
  };
  return (
    <>
      <ModalLoading
        visible={openModalVisible}
        onClick={() => {
          setIsModalVisible(false);
          !!setRefetch && setRefetch(true);
        }}
        progress={progress}
        infoLoading={(
          <Section
            className="shadow-none"
            classNameTitle="text-flexidocs-turquoise"
            title="Compte rendu de la suppression de l'offre"
          >
            <div className="flex justify-between">
              <p>Documents édités supprimés</p>
              {statusItemRemoved('documents')}
            </div>
            <div className="flex justify-between">
              <p>Documents importés supprimés</p>
              {statusItemRemoved('rawDocuments')}
            </div>
            <div className="flex justify-between">
              <p>Widgets supprimés</p>
              {statusItemRemoved('widgets')}
            </div>
            <div className="flex justify-between">
              <p>Tags supprimés</p>
              {statusItemRemoved('tags')}
            </div>
            <div className="flex justify-between">
              <p>Variables globales supprimées</p>
              {statusItemRemoved('globalVariables')}
            </div>
            <div className="flex justify-between">
              <p>Trames de comparaison supprimées</p>
              {statusItemRemoved('frames')}
            </div>
            {equals(100, progress) && errorRemoveOffer && (
              <Alert
                type="error"
                message={(
                  <p>
                    {'Impossible de supprimer correctement les éléments de l\'offre, veuillez contacter l\'équipe Flexidocs'}
                  </p>
                )}
              />
            )}
          </Section>
        )}
        textProgression={`${progress}%`}
      />
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightFilled
            className={`!text-[18px] relative !mr-1.5 top-0.5 ${isActive ? '!text-flexibranche-lightblue' : '!text-gray-600'}`}
            rotate={isActive ? 90 : 0}
          />
        )}
        bordered={false}
        className="versions_tree bg-white"
      >
        {map(item => {
          if (either(isNil, isEmpty)(prop('versions', item))) {
            return null;
          }

          const lastVersion = compose(
            head,
            propOr([], 'versions')
          )(item);

          const majorVersions = compose(
            groupBy(path(['version', 'major'])),
            propOr([], 'versions')
          )(item);

          const majorVersionsNumbers = compose(
            reverse,
            keys
          )(majorVersions);

          return (
            <Collapse.Panel
              key={prop('id', item)}
              // eslint-disable-next-line no-restricted-syntax
              style={panelItemStyle}
              header={(
                <VersionsTreeItem
                  name={prop(nameField, lastVersion)}
                  dates={prop('dates', lastVersion)}
                  version={prop('version', lastVersion)}
                  editLink={buildEditLink(prop('id', lastVersion))}
                  suppressionLabel={suppressionLabel}
                  archiveLabel={archiveLabel}
                />
              )}
            >
              <Timeline mode="left">
                {map(versionNumber => {
                  const minorVersions = propOr([], versionNumber, majorVersions);

                  return (
                    <Timeline.Item
                      key={`${item.id}-version-${versionNumber}`}
                      color="green"
                      dot={<Dot color="#61c397" />}
                      // eslint-disable-next-line no-restricted-syntax
                      style={timelineItemStyle}
                    >
                      Version {versionNumber}

                      <Timeline mode="left" className="!mt-3">
                        {mapIndexed((itemVersion, index) => {
                          const itemId = path(['version', 'itemId'], itemVersion);
                          const versionId = path(['version', 'versionId'], itemVersion);
                          const major = path(['version', 'major'], itemVersion);
                          const minor = path(['version', 'minor'], itemVersion);

                          return (
                            <Timeline.Item
                              key={`${prop('id', item)}-version-${versionNumber}-${itemVersion.id}`}
                              color={index !== 0 ? 'gray' : 'blue'}
                              dot={<Dot color={index !== 0 ? 'gray' : '#0197dc'} />}
                              // eslint-disable-next-line no-restricted-syntax
                              style={timelineItemStyle}
                            >
                              <Link to={buildEditLink(prop('id', itemVersion))}>Version {`${major}.${minor}`}</Link>
                              <span>
                      &nbsp;({getStatusLabel(path(['version', 'status'], itemVersion))})
                              </span>

                              {userCanEdit && (
                                <div className="float-right">
                                  {statusIs('archived', prop('version', itemVersion))
                                    ? (
                                      <Popconfirm
                                        placement="topRight"
                                        title={suppressionLabel}
                                        onConfirm={onRemove(versionId, itemId)}
                                        okText="Oui"
                                        cancelText="Non"
                                      >
                                        <a href="#">Supprimer</a>
                                      </Popconfirm>
                                    )
                                    : (
                                      <Popconfirm
                                        placement="topRight"
                                        title={archiveLabel}
                                        onConfirm={onArchive(versionId, itemId)}
                                        okText="Oui"
                                        cancelText="Non"
                                      >
                                        <a href="#">Archiver</a>
                                      </Popconfirm>
                                    )
                                  }
                                </div>
                              )}
                            </Timeline.Item>
                          );
                        })(minorVersions)}
                      </Timeline>
                    </Timeline.Item>
                  );
                })(majorVersionsNumbers)}
              </Timeline>
            </Collapse.Panel>
          );
        })(items)}
      </Collapse>
    </>
  );
};

export default VersionsTree;

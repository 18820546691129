import React, { useState } from 'react';
import { isEmpty } from 'ramda';
import { Actions } from '../../components';
import Title from '../../components/Title';
import withUser from '../../withUser';
import { QOffers } from '../../_graphql/queries';
import OfferList from './offerList/OfferList';

const Offers = ({ userIsAdmin }) => {
  const [refetch, setRefetch] = useState(false);

  return (
    <QOffers args={{ refetch, setRefetch }}>
      {(offers) => (
        <>
          {userIsAdmin && (
            <Actions
              listEmpty={isEmpty(offers)}
              actions={[
                {
                  label: 'Créer une offre',
                  to: '/offre/creer'
                }
              ]}
              title={(
                <Title
                  title="mes offres"
                  icon="/images/icon-offre-dark@2x.png"
                  iconSize={[21, 18]}
                  iconOffset={-2}
                />
              )}
            />
          )}
          <OfferList userCanEdit={userIsAdmin} offers={offers} setRefetch={refetch} />
        </>
      )}
    </QOffers>
  );
};

export default withUser(Offers);

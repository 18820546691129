import React, { useState } from 'react';
import { map, propOr, compose, find, pathEq, path } from 'ramda';
import { Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { TEAM_ROLES } from '../../../lib/rights';
import { ChevronBottom } from '../../../components';
import { useUserContext } from '../../../withUser';
import UpdateTeamUserRoleMutation from '../../../_graphql/mutations/teams/UpdateTeamUserRoleMutation';
import { errorNotification, successNotification } from '../../../utils/messageMutation';

const useManageRole = (userTeam) => {
  const history = useHistory();
  const { userRefetch } = useUserContext();

  const updateRole = (userId, role) => {
    UpdateTeamUserRoleMutation({
      teamId: userTeam.teamId, userId, role
    }, (ok, error) => {
      if (ok && !error) {
        successNotification('Le rôle de l\'utilisateur a été mis à jour.');
        userRefetch();
        history.push('/equipe');
      } else {
        errorNotification();
      }
    });
  };

  return {
    updateRole,
    history
  };
};

const useRole = (member) => {
  const [role, setRole] = useState(propOr('regular', 'role', member));
  return {
    role,
    setRole
  };
};

const ManageRoleDropdown = ({ userTeam, memberId }) => {
  const member = compose(
    find(pathEq(['user', 'id'], memberId)),
    propOr([], 'members')
  )(userTeam);

  const {
    role,
    setRole
  } = useRole(member);

  const { updateRole } = useManageRole(userTeam);

  const onChange = (value) => {
    setRole(value);
    updateRole(path(['user', 'id'], member), value);
  };

  return (
    <Select
      placeholder="Choisir un rôle"
      value={role}
      onChange={onChange}
      className="w-full"
      size="large"
      suffixIcon={<ChevronBottom />}
    >
      {map(({ label, value }) => {
        return (
          <Select.Option
            key={value}
            value={value}
          >
            {label}
          </Select.Option>
        );
      })(TEAM_ROLES)}
    </Select>
  );
};

export default ManageRoleDropdown;

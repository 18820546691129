import { graphql, requestSubscription } from 'react-relay';

export const FinishedDuplicateOfferSubscriptionQuery = graphql`
  subscription FinishedDuplicateOfferSubscription($filter: BaseSubscriptionInput!) {
    finishedDuplicateOfferSubscription(filter: $filter) {
      id
      step
      widgets
      documents
      rawDocuments
      tags
      globalVariables
      folders
      frames
    }
  }
`;

export default (environment) => {
  return requestSubscription(
    environment,
    {
      subscription: FinishedDuplicateOfferSubscriptionQuery,
      variables: {},
      updater: (
        store,
        { finishedDuplicateOfferSubscription }
      ) => {
        const { id, step, widgets, documents, rawDocuments, tags, globalVariables, folders, frames } = finishedDuplicateOfferSubscription;
        let record = store.get(id);

        if (!record) {
          record = store.create(id, 'duplicateOffer');
        }

        record.setValue(step, 'step');
        record.setValue(widgets, 'widgets');
        record.setValue(documents, 'documents');
        record.setValue(rawDocuments, 'rawDocuments');
        record.setValue(tags, 'tags');
        record.setValue(globalVariables, 'globalVariables');
        record.setValue(folders, 'folders');
        record.setValue(frames, 'frames');
      },
      onCompleted: () => {},
      onError: error => {
        console.error('SUBSCRIPTION FINISHED DUPLICATE OFFER ERROR:', error.message);
      }
    }
  );
};

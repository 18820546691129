import React, { useState } from 'react';
import { assoc, isNil, pick, prop, propOr } from 'ramda';
import { useHistory } from 'react-router-dom';
import { Button, Input } from 'antd';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { Components } from '@fasstech/flexiget';
import { Section, Title } from '../../../../components';
import UpdateColorMutation from '../../../../_graphql/mutations/documents/UpdateColorMutation';
import CreateColorMutation from '../../../../_graphql/mutations/documents/CreateColorMutation';
import { errorMessage } from '../../../../utils/messageMutation';

const colorIsValid = color => {
  const test = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
  return test.test(color);
};

const useColor = (colorId, color) => {
  const [state, setState] = useState({
    name: propOr('', 'name', color),
    value: prop('value', color)
  });

  const history = useHistory();

  const isEdit = !isNil(colorId);
  const title = isEdit ? 'Modification d\'une couleur' : 'Création d\'une couleur';
  const isValid = isNotNilOrEmpty(prop('name', state)) && isNotNilOrEmpty(prop('value', state)) && colorIsValid(prop('value', state));

  const onSave = () => {
    const color = pick(['name', 'value'], state);

    const callback = (ok, error) => {
      if (ok && !error) {
        history.push('/equipe/couleurs');
      } else {
        errorMessage();
      }
    };

    if (isEdit) {
      UpdateColorMutation({ colorId, color }, callback);
    } else {
      CreateColorMutation({ color }, callback);
    }
  };

  return {
    ...state,
    setState,
    isEdit,
    title,
    onSave,
    isValid
  };
};

const ColorForm = ({ colorId, color }) => {
  const {
    isEdit,
    title,
    name,
    value,
    setState,
    onSave,
    isValid
  } = useColor(colorId, color);

  return (
    <div className="mt-4">
      <Title title={title} backTo="/equipe/couleurs" />
      <Section>
        <label className="block mb-2 font-bold">Nom de la couleur</label>
        <Input
          size="large"
          value={name}
          placeholder="Nom de la couleur"
          onChange={e => setState(assoc('name', e.target.value))}
        />
        <label className="mb-2 block font-bold mt-6">Couleur</label>
        <div className="flex items-start">
          <Components.ColorPickerBody
            color={value || '#000'}
            setColor={v => setState(assoc('value', v))}
            handleColorChange={v => setState(assoc('value', v))}
            favoritesColors={[]}
          />
        </div>
      </Section>
      <div className="text-center">
        <Button
          size="large"
          onClick={onSave}
          disabled={!isValid}
          type="primary"
          className="mt-4"
        >
          {isEdit ? 'Mettre à jour la couleur' : 'Créer la couleur'}
        </Button>
      </div>
    </div>
  );
};

export default ColorForm;

import React from 'react';
import { Route } from 'react-router-dom';
import { prop } from 'ramda';
import TagDocument from '../docs/TagDocument';
import PreviewWidget from '../docs/PreviewWidget';
import WidgetHistory from '../docs/WidgetHistory';
import Styles from './styles/Styles';
import CreateStyle from './styles/CreateStyle';
import EditStyle from './styles/EditStyle';
import Widgets from './Widgets';
import StyleHistory from './styles/StyleHistory';
import CreateColor from './colors/CreateColor';
import EditColor from './colors/EditColor';
import Colors from './colors/Colors';
import Tags from './tags/Tags';
import CreateTag from './tags/CreateTag';
import DefaultFont from './defaultFont/DefaultFont';

const Routes = ({
  match
}) => {
  const matchUrl = prop('url', match);
  return (
    <>
      <Route exact path={`${matchUrl}/widgets`} component={Widgets}/>
      <Route path={`${matchUrl}/widgets/:widgetId/preview`} component={PreviewWidget}/>
      <Route path={`${matchUrl}/widgets/:widgetId/historique`} component={WidgetHistory}/>

      <Route path={`${matchUrl}/styles/creer`} component={CreateStyle}/>
      <Route path={`${matchUrl}/styles/:styleId/historique`} component={StyleHistory}/>
      <Route path={`${matchUrl}/styles/:styleId/edition`} component={EditStyle}/>
      <Route exact path={`${matchUrl}/styles`} component={Styles}/>

      <Route path={`${matchUrl}/couleurs/creer`} component={CreateColor}/>
      <Route path={`${matchUrl}/couleurs/:colorId/edition`} component={EditColor}/>
      <Route exact path={`${matchUrl}/couleurs`} component={Colors}/>

      <Route exact path={`${matchUrl}/police`} component={DefaultFont}/>

      <Route path={`${matchUrl}/tags/:tagId/preview`} component={TagDocument}/>
      <Route path={`${matchUrl}/tags/creer`} component={CreateTag}/>
      <Route path={`${matchUrl}/tags/:tagId/edition`} component={TagDocument}/>
      <Route exact path={`${matchUrl}/tags`} component={Tags}/>
    </>
  );
};

export default Routes;

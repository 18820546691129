/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BaseSubscriptionInput = {|
  itemIds?: ?$ReadOnlyArray<?string>,
  userId: string,
|};
export type FinishedDuplicateOfferSubscriptionVariables = {|
  filter: BaseSubscriptionInput
|};
export type FinishedDuplicateOfferSubscriptionResponse = {|
  +finishedDuplicateOfferSubscription: ?{|
    +id: ?string,
    +step: ?number,
    +widgets: ?boolean,
    +documents: ?boolean,
    +rawDocuments: ?boolean,
    +tags: ?boolean,
    +globalVariables: ?boolean,
    +folders: ?boolean,
    +frames: ?boolean,
  |}
|};
export type FinishedDuplicateOfferSubscription = {|
  variables: FinishedDuplicateOfferSubscriptionVariables,
  response: FinishedDuplicateOfferSubscriptionResponse,
|};
*/


/*
subscription FinishedDuplicateOfferSubscription(
  $filter: BaseSubscriptionInput!
) {
  finishedDuplicateOfferSubscription(filter: $filter) {
    id
    step
    widgets
    documents
    rawDocuments
    tags
    globalVariables
    folders
    frames
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "DuplicateOfferResult",
    "kind": "LinkedField",
    "name": "finishedDuplicateOfferSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "step",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "widgets",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documents",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawDocuments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tags",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "globalVariables",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "folders",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "frames",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FinishedDuplicateOfferSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FinishedDuplicateOfferSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5b6cbf83f1aaf7523d778f2e0bbb72a3",
    "id": null,
    "metadata": {},
    "name": "FinishedDuplicateOfferSubscription",
    "operationKind": "subscription",
    "text": "subscription FinishedDuplicateOfferSubscription(\n  $filter: BaseSubscriptionInput!\n) {\n  finishedDuplicateOfferSubscription(filter: $filter) {\n    id\n    step\n    widgets\n    documents\n    rawDocuments\n    tags\n    globalVariables\n    folders\n    frames\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd87d38302f3c419bfad728b4214dc5a4';

module.exports = node;

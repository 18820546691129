import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { BgFill, DoubleTags, DoubleFont, Cube, Font } from '../../components/icons';
import { Section } from '../../components';

const ElementsCard = ({ children, to, icon }) => {
  const history = useHistory();
  const className = classNames(`${to}-elements-card border border-solid rounded p-7 text-xs cursor-pointer flex justify-between uppercase font-bold border-gray-200`);
  return (
    <div
      className={className}
      onClick={() => history.push(`/equipe/${to}`)}
    >
      <div className="flex items-baseline gap-4">
        <div className="text-2xl text-flexibranche-blue">
          {icon}
        </div>
        {children}
      </div>
      <img src="/images/chevron-left.svg" alt={`voir ${children}`} width="10px" />
    </div>
  );
};

const Elements = () => {
  return (
    <Section title="Les éléments de mon équipe">
      <div className="grid md:grid-cols-5 xs:grid-cols-1 gap-6">
        <ElementsCard to="widgets" icon={<Cube />}>
          Widgets
        </ElementsCard>

        <ElementsCard to="styles" icon={<DoubleFont />}>
          Styles
        </ElementsCard>

        <ElementsCard to="couleurs" icon={<BgFill />}>
          Couleurs
        </ElementsCard>

        <ElementsCard to="tags" icon={<DoubleTags />}>
          Tags
        </ElementsCard>

        <ElementsCard to="police" icon={<Font />}>
          Police
        </ElementsCard>
      </div>
    </Section>
  );
};

export default Elements;
